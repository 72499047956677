import { render, staticRenderFns } from "./toothpaste.vue?vue&type=template&id=642a1f81&scoped=true&"
import script from "./toothpaste.vue?vue&type=script&lang=js&"
export * from "./toothpaste.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642a1f81",
  null
  
)

export default component.exports