<template>
  <div>
    <tabBar :activeIndex="0" :mouseenterIndex="20" />
    <div class=""></div>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import homeDrag from "@/components/homeDrag.vue";
import footers from "@/components/footer.vue";
export default {
  components: {
    tabBar,
    footers,
  },
};
</script>

<style lang="scss" scoped>
</style>